body {
  min-height: 100vh;
  background: #f9f9f9;
  overflow-y: scroll;
}

body > #__next {
  min-height: inherit;
}

.lock-scroll {
  overflow: hidden;
}

/* Remove focus outline for mouse users, polyfilled for Safari with https://github.com/WICG/focus-visible */
.js-focus-visible :focus:not(.focus-visible) {
  /* Fallback for Windows high contrast mode - https://craftcms.com/accessibility/custom-focus-indicators-with-css-variables */
  outline-color: transparent;
  outline-style: solid;
}
